@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Open+Sans&display=swap');

.top-gallery{
    font-size: 300%;
    text-align: center;
    width: 100%;
    background: linear-gradient(90deg,#AF40FF, #5B42F3 50%,#00DDEB);
    object-fit: contain;
    color: black;
    display: flex;
    margin-bottom: 3%;
}
.page-text{
    margin: 10% auto 4% auto;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 100%;
    color: white;
    text-shadow: 2px 2px 8px #ff005d;
}

.image-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 3%;
}
.each-item{
    width: 30%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    margin: 20px;
    box-shadow: 10px 10px lightblue;
    transition: all 1s ease-in-out;
    overflow: hidden;
}
.image-gallery:hover{
    transform: scale(1.5);
    transition-duration: 1s;
    cursor: pointer;
}
.image-gallery{
    width: 100%;height: 100%;
    border-radius: 10px;
    transition: all 1s ease-in-out;
}

@media screen and (max-width:926px) {
    .page-text{
        margin-top: 60px;
    }
    .image-row{
        margin-left: 10px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .each-item{
        width: 50%;
        height: auto;
        border-radius: 10px;
        object-fit: contain;
        margin: 20px;
        box-shadow: 10px 10px lightblue;
        transition: all 0.5s ease-in-out;
    }
    .each-item:hover{
        width: 80%;
        box-shadow: none;
    }

}