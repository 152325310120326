@import url('https://fonts.googleapis.com/css2?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@700&family=Open+Sans&display=swap');

li {
  list-style: none;
}

a {
  color: black;
  text-decoration: none;
  font-size: 450%;
  line-height: 40px;
}
.container{
  position: fixed;
  margin: auto;
  width: 100%;
  transition: all 0.6s;
  z-index: 2;
}

.nav-items a:hover{
  color: #78C1F3;
  text-decoration: overline;
}


.navbar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: auto;
  width: 88%;
  margin: auto;
  border-width: medium;
  border-radius: 2rem;
  margin-top: 10px;
  transition: all 0.6s;
  background: transparent;
}

.navbar-activate {
  display: flex;
  justify-content: space-between;
  /* padding: 10px; */
  height: auto;
  width: 100%;
  margin: auto;
  /* border: 1px solid #3DB2FF; */
  /* border-width: medium;
  border-radius: 2rem; */
  /* margin-top: 10px; */
  transition: all 0.6s;
  background-color: black;
  transition: 0.6s;
}

.logo {
  /* cursor: pointer; */
  display: block;
  height: auto;
  margin-left: 3%;
  width: 180px;
  height: auto;
  /* background-color: #161616; */
  padding: 5px;
  transition: all 0.6s;
}

.nav-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-left: 5px;
  transition: all 0.6s;
}
.nav-items a {
  font-family: 'Open Sans', sans-serif;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}
.nav-items li a {
  margin-right: 2rem;
}

.navbar .active {
  background: transparent;
}
.navbar-activate .active {
  color: #068FFF;
}
.non-active{
  color: white;
}

.mobile-menu-icon {
  display: none;
}

@media screen and (max-width: 1100px) {
  .nav-items a {
    font-size: medium;
  }
  .nav-items a:hover{
  color: #78C1F3;
  text-decoration: overline;
}
  .navbar .active {
    background: none;
  color: #068FFF;
}
}

@media screen and (max-width: 926px) {
  .navbar {
    display: flex;
    height: auto;
    border: none;
    width: auto;
    justify-content: space-evenly;
    border-radius: 0%;
    background: transparent;
  }
  .nav-items a:hover{
  color: #78C1F3;
  text-decoration: overline;
}
  .navbar-activate{
    display: flex;
    height: auto;
    border: none;
    width: auto;
    justify-content: space-evenly;
    border-radius: 0%;
    background-color: black;
  }
  .navbar .icon-color{
    color: white;
  }
  
  .navbar-activate .icon-color{
    color: white;
  }

  .logo-resizer {
    width: 180px;
    height: 47px;
  }

  .nav-items-mobile {
    position: absolute;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: auto;
    top: 100%;
    /* top: 95px; */
    transition: all 0.5s ease-out;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    /* border: 2px solid #3DB2FF; */
    animation: fadeIn 0.3s ease-in-out;
  }

  .nav-items-mobile li {
    display: flex;
  }

  .navbar .nav-items-mobile li a {
    text-align: center;
    width: 100%;
    padding: 20px;
    transition: all 0.5s ease;
    text-decoration: none;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
  }
  .navbar-activate .nav-items-mobile li a {
    text-align: center;
    width: 100%;
    padding: 20px;
    text-transform: capitalize;
    transition: all 0.5s ease;
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
  }

  .nav-items {
    display: none;
  }
  
  .navbar .active {
    background: none;
  color: #068FFF;
}

  .logo {
    margin: 0;
    padding: 0;
    margin-left: 1rem;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  .navbar {
    margin: 0;
    padding: 0;
  }

  .mobile-menu-icon {
    display: block;
    position: relative;
    font-size: 30px;
    color: white;
    background-color: transparent;
    border: none;
    outline: none;
    margin-right: 20px;
    transition: all 1s ease-in-out;
  }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
