@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');

.logo-ieee{
    background-color: white;
}
.footer_main{
    background-color: #111111;
    padding-top: 10px;
    margin-top: 3%;
    margin-bottom: 0px;
    height: 100%;
}

.ruler{
    border: 1px solid #920e8c;
    display: block;
    width: 95%;
}
.footer {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;

}

.Footer-name{
  background: -webkit-linear-gradient(36deg,#a940fe, #06d3ec);
  margin-left: 10px;
  -webkit-background-clip: text;
  margin-left: 10px;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.thankstext{
    font-family: Poppins;
    margin-left: 10px;
    font-weight: 300;
    color: #ffffff;
}

.container-1 {
    display: flex;
}

.footer-main {
    flex-grow: 1;
    flex-basis: 100%;
}

.col-1 {
    display: flex;
    flex-grow: 1;
    flex-basis: 100%;
}

.footer-main p {
    font-size: 100%;
    text-align: left;
    font-family: Poppins;
    padding: 5px;

}

.footer-links {
    list-style: none;
}

.footer-links li {
    margin: 0 10px;
    padding: 10px;

}

.footer-links li a {
    color: #EEEEEE;
    font-weight: 400;
    text-decoration: none;
    font-size: medium;
    font-family: "Raleway";
}

.col-1 ul li a:hover {
    color: #ffffff;
    font-weight: 400;
    padding-left: 2px;
    transition: all 0.2s ease-in;
}

.footer-links li .foot-head{
    color: #EEEEEE;
    font-size: 18px;
    margin-bottom: 18px;
    font-weight: 500;
    position: relative;
    font-family: "Raleway";

}

.bottom-content {
    font-size: 14px;
    text-align: center;
    background-color: black;
    padding-bottom: 5px;
    width: 100%;
    margin-bottom: 10px;
}

.social-media {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 0;
    margin-bottom: -15px;
}
.icons{
    color: grey;
    transition: all 0.25s ease-in-out;
}

.icons:hover{
    color: #3DB2FF;
}
.bottom {
        display: block;
        font-family: "Raleway";
        font-size: 130%;
        font-weight: 400;
        height: 100%;
        color: white;
        padding-top: 10px;
        padding-bottom: 10px;
    }


@media screen and (max-width:576px) {
    .footer_main{
        margin-top: 50px;
    }
    .ruler{
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
    .APP {
        min-height: 50vh;
    }

    .footer {
        margin-top: 13px;
        margin-left: 0%;
        margin-right: 0;
        position: sticky;
        display: grid;
        position: -webkit-sticky;
    }

    .container-1 {
        display: block;
    }

    .bottom {
        display: block;
    }
}



@media screen and (max-width:768px) {
     .footer_main{
        margin-top: 50px;
    }
    .container-1 {
        display: block;
    }
    .footer {
        position: sticky;
        margin-left: 0%;
        position: -webkit-sticky;
    }

    .bottom {
        display: block;
    }
}

@media screen and (max-width:991px) {
     .footer_main{
        margin-top: 50px;
    }
    .container-1 {
        display: block;
    }

    .footer {
        position: sticky;
        margin-left: 0%;
        position: -webkit-sticky;
    }

    .bottom {
        display: block;
    }
}