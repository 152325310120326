@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.image-container {
  position: relative;
  display: block;
  width: calc(33.33% - 20px); 
  margin-top: 10px;
}

.eventimage {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.image-events {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  background-color: white; 
  padding: 20px; 
  margin-bottom: 40px;
}


.dialog-box {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  padding: 10px;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.4s linear;
}
.info, .date{
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 100%;
}
.image-container:hover .dialog-box {
  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  border: 1px solid black;
}

.enlarged-image {
  width: 100%;
  height: 100%;
}

.description {
  margin-top: 10px;
}

.EventsPage{
  margin-bottom: 20px;
}

@media (max-width: 800px) {
  .image-container{
    margin-left: auto;
    margin-right: auto;
    width: max-content;
  }
  .image-events{
    display: flex;
    flex-direction: column;
  }
  .dialog-box {
  position: absolute;
  top: 0%;
  left: 0%;
  transform: none;
  width: 200px;
  }
  .enlarged-image {
    display: none;
  }

}