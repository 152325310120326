@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Raleway&family=Titillium+Web&display=swap');

.top-chapter{
    font-size: 300%;
    text-align: center;
    width: 100%;
    background: linear-gradient(90deg,#AF40FF, #5B42F3 50%,#00DDEB);
    object-fit: contain;
    color: black;
    display: flex;
    margin-bottom: 3%;
}

.page-text{
    margin: 10% auto 4% auto;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 100%;
}
.chapter-contents{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-bottom: 3%;
}
.chapter-image{
    width: 30%;
    height: auto;
    object-fit: contain;
}
.soc-text{
    display: flex;
    flex-direction: column;
    align-content: space-around;
    padding: 1.5rem;

}
.soc-title{
    font-family: 'Titillium Web';
    font-size: 200%;
    font-weight: 500;
    text-align: center;
}
.soc-sub{
    font-family: 'Raleway';
    font-size: 100%;
    font-weight: 600;
    text-align: left;
}

.soc-1{
    display: flex;
    flex-direction: row;
    height: min-content;
    background-color: rgba(232, 160, 191, 0.6);
    padding: 1rem;
    margin: 2.5% 5%;
    border: 1px solid #E8A0BF;
    border-width: medium;
    border-radius: 2rem;
}

.soc-2{
    display: flex;
    flex-direction: row;
    height: min-content;
    background-color: rgba(186, 144, 198, 0.6);
    padding: 1rem;
    margin: 2.5% 5%;
    border: 1px solid #BA90C6;
    border-width: medium;
    border-radius: 2rem;
}

.soc-3{
    display: flex;
    flex-direction: row;
    height: min-content;
    background-color: rgba(178, 164, 255, 0.7);
    padding: 1rem;
    margin: 2.5% 5%;
    border: 1px solid #B2A4FF;
    border-width: medium;
    border-radius: 2rem;
}
.soc-4{
    display: flex;
    flex-direction: row;
    height: min-content;
    background-color: rgba(192, 219, 234, 0.7);
    padding: 1rem;
    margin: 2.5% 5%;
    border: 1px solid #C0DBEA;
    border-width: medium;
    border-radius: 2rem;
}

.soc-5{
    display: flex;
    flex-direction: row;
    height: min-content;
    background-color: rgba(193, 239, 255, 0.7);
    padding: 1rem;
    margin: 2.5% 5%;
    border: 1px solid #C1EFFF;
    border-width: medium;
    border-radius: 2rem;
}

.soc-6{
    display: flex;
    flex-direction: row;
    height: min-content;
    background-color: rgba(255, 233, 174, 0.7);
    padding: 1rem;
    margin: 2.5% 5%;
    border: 1px solid #FFE9AE;
    border-width: medium;
    border-radius: 2rem;
}

.soc-7{
    display: flex;
    flex-direction: row;
    height: min-content;
    background-color: rgba(255, 179, 179, 0.7);
    padding: 1rem;
    margin: 2.5% 5%;
    border: 1px solid #FFB3B3;
    border-width: medium;
    border-radius: 2rem;
}

.anchor{
    text-decoration: none;
}

@media screen and (max-width:768px) {
    .page-text{
        margin-top: 60px;
    }
    .soc-1{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .soc-2{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .soc-3{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .soc-4{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .soc-5{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .soc-6{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .soc-7{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
    .chapter-image{
        margin: auto;
        width: 100%;
        height: auto;
        object-fit: contain;
    }
}
