.spin-head{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
}
.load-img{
  width: 200px;
  text-align: center;
  margin: auto;
}
.loading-text{
   background: -webkit-linear-gradient(30deg,#b06be9, #0ec0d8);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-weight: 500;
}
